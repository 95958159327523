
import {
  defineComponent, ref
} from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmEllipsis from '@/components/shared/TmEllipsis.vue'
import SinglePageContent from '@/components/layout/SinglePageContent.vue'
import { formatNumber } from '@/services/utils'

export default defineComponent({
  components: {
    TmFormLine,
    SinglePageContent,
    TmButton,
    TmEllipsis,
  },
  setup() {
    const companyName = ref('')

    const organizationTypeVariant = [
      'Franchise',
      'Government or state organization',
      'Licensed professional',
      'Public utility',
      'Registered corporation',
      'Non-Profit organization',
      'Sole proprietor',
    ]
    const organizationType = ref('Franchise')

    const stateOfIncorporation = ref('')

    const addressLine1 = ref('')
    const addressLine2 = ref('')

    const city = ref('')
    const state = ref('')
    const zip = ref('')

    const description = ref('')

    const typeMessageVariant = ['Marketing', 'Informational']
    const typeMessage = ref('Marketing')

    const information = ref('')
    const sampleMessage = ref('')

    const categoryVariant = [
      'Account management and reminders',
      'Appointment reminders and service promotions',
      'Banking and lending institution services',
      'Educational services',
      'Existing customer marketing/promotions',
      'Financial or employment service referrals',
      'Group messaging',
      'Health services',
      'Interactive messaging',
      'Internal employee communications',
      'New customer marketing/promotions',
      'News and content alerts',
      'Non-profit messaging',
      'Political messaging',
      'Product or service delivery',
      'Sweepstakes and lotteries',
      'Two-factor authentication',
      'Other',
    ]
    const category = ref('Account management and reminders')

    const oneNumber = ref(false)

    const estimatedMessageVariant = [10, 100, 1000, 10000, 100000, 250000, 500000, 1000000, 5000000]
    const estimatedMessage = ref(1000)

    const canadianTrafficVariant = ['Yes', 'No']
    const canadianTraffic = ref('No')

    const uploadInput = ref<HTMLInputElement | null>(null)
    const file = ref<null | string>(null)

    const uploadFile = (e: any) => {
      if (e.target.files[0].name) {
        file.value = e.target.files[0].name
      } else {
        file.value = null
      }
    }

    const clickToChooseFile = () => {
      if (uploadInput.value) uploadInput.value.click()
    }

    return {
      formatNumber,
      companyName,
      organizationTypeVariant,
      organizationType,
      stateOfIncorporation,
      addressLine1,
      addressLine2,
      city,
      state,
      zip,
      description,
      typeMessageVariant,
      typeMessage,
      information,
      sampleMessage,
      categoryVariant,
      category,
      oneNumber,
      estimatedMessageVariant,
      estimatedMessage,
      canadianTrafficVariant,
      canadianTraffic,
      uploadInput,
      uploadFile,
      clickToChooseFile,
      file,
    }
  },
})
